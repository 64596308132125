import featureImg from '../coreImg/portfolio-demo.jpg';

const PortfolioDetails = () => {
	const features = [
		{
			name: 'Goal',
			description: `To play with TailwindCSS and see what the hype is about`,
		},
		{
			name: 'Back End',
			description: `I don't think I need one for this, so I try not to use one`,
		},
		{
			name: `Front End`,
			description: `ReactJs, TailwindCSS, HTML, React Router Dom, React Markdown`,
		},
		{
			name: `Challenges`,
			description: 'Site structure, dynamic routing, and responsive design',
		},
		{
			name: 'Features',
			description: `Multi column design, fully responsive, data parsing from markdown, and incredibly fast`,
		},
		{
			name: 'External Resources',
			description:
				'MailModo mail API for contact form, Reddit API for eli5, Vercel for deployment',
		},
	];

	const details = [
		{
			name: `Ideas`,
			description: `I'm a huge fan of minimalism and I love the idea of having a multi column design for my site, heavily inspired by the design of Apple Notes and Mail apps which I use everyday. It's simple, straight to the point, and super fast to navigate around.`,
		},
		{
			name: 'First step, tech stack',
			description: `I've worked with markdown before, and with this site involving a lot of content posting, markdown is the best choice. I didn't know how to render it inside React, but I was sure there'd be a way. After searching around, it was surprisingly not many articles talking about using markdown with create-react-app (CRA) itself, but with NextJs instead. Apparently, NextJs is the best framework to build a static blog. But I thought to myself, it's not time to learn a new framework yet, let's stick with just CRA to improve my skills and see if I can get it figured out`,
		},
		{
			name: 'Making it responsive',
			description: `Building responsive design is not easy, but building responsive multi column design for me is another level b!tch. Man, sometimes I wondered what got me into building this witchcraft. It has 3 layers of nested components on just 1 single desktop page, with empty state and selected state, so which view to be shown on mobile, when, how to select, and how to navigate between them? It took me 3 entire days just to come up with a proper way to handle the structure using just z-index & useState hook.`,
		},
		{
			name: 'Fancy stuff',
			description: `How to keep the parent components unchanged when changing the child components along with the URL, even just the mouse position, or the highlight state. Even though everything I read recommended <NavLink>, I initially used <a href> tag for all the navigation because I thought I couldn't decorate NavLink. so whenever I clicked on a link, it reloads the entire thing, which ruined the beauty of multi column design in the first place. I tried lots of different things, from history.replace to useParams, nothing worked as I wanted. In a desparate attempt, I put the <NavLink> inside a <li> tag and it worked like a charm. So stupid of me.`,
		},
		{
			name: 'The contact form',
			description: `I know I needed something to handle the contact form without having to build an entire database for it, kinda overkill. So I searched around and was quite happy with MailJet. They have good free threadhold, well documented API (not true when I tried to integrate). I set it all up just to find out they have CORS limit. Meh, I was not interested in using a proxy for just that, so I kept on finding another one. After trying out a couple more, I settled down with MailModo. They're not quite built for this job specifically, but I got it working for me, so why not.`,
		},
		{
			name: 'iframe refused to connect',
			description: `In the bookmarks section, I wanted to render directly these external websites inside it using iframe. Got it working for the first test site and I was so excited. Added a couple more and found it refused to connect when loading via iframe. So I learned about the X-Frame-Options. I still haven't figured out a way to handle it yet, other than showing a little notification telling people to visit the original site when it's not loaded.`,
		},
		{
			name: 'fetching data from Reddit',
			description: `Reddit API is tricky. Their system is huge, so you can easily get lost in there. Also, good luck finding a decent API wrapper for Node. The only option is snoowrap which requires you to install & config a whole bunch of packages nolonger included in webpack 5. What a headache.`,
		},
		{
			name: 'Overrall',
			description: `To be honest, I wasn't quite ready to take on this project when I started it yet. Much more complex than any other projects I had done before. One thing I got working, another one broke, it just repeats like that. But I'm glad I got it all working now, and I've learnt a lotttt. And yeah, TailwindCSS is good.`,
		},
	];

	return (
		<div className="z-10 max-w-full relative overflow-y-auto pb-24 min-h-screen bg-neutral-900 text-neutral-200">
			<div className="bg-neutral-900 mb-12 space-y-12">
				<div aria-hidden="true" className="relative">
					<img
						src={featureImg}
						alt=""
						className="w-full h-96 object-center object-cover"
					/>
					<div className="absolute inset-0 bg-gradient-to-t from-neutral-900" />
				</div>

				<div className="relative bg-neutral-900 px-5 lg:px-24">
					<div className="max-w-2xl mx-auto text-center lg:max-w-4xl">
						<h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
							This Portfolio
						</h2>
						<p className="mt-4">
							I had been writing on WordPress blog for the longest time ever and
							now since I've picked up web development, it's time for me to
							build a new site from scratch using ReactJs. Plus, it's my first
							attempt with TailwindCSS.
						</p>
					</div>
					<dl className="mt-16 max-w-2xl mx-auto grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:max-w-none lg:grid-cols-3 lg:gap-x-8">
						{features.map((feature) => (
							<div key={feature.name} className="border-t border-gray-200 pt-4">
								<dt className="font-semibold">{feature.name}</dt>
								<dd className="mt-2 text-sm">{feature.description}</dd>
							</div>
						))}
					</dl>
					<a
						className="flex space-x-2 mt-8 flex-none items-center justify-center cursor-pointer font-semibold px-4 py-2 text-sm rounded-lg text-white hover:text-white shadow-xs bg-blue-500 border border-blue-600 dark:border-blue-400 dark:border-opacity-50 hover:shadow-sm"
						href="/"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							className="bi bi-link-45deg"
							viewBox="0 0 16 16"
						>
							<path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
							<path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
						</svg>
						<span>Visit (Well, you're already here)</span>
					</a>
				</div>
				{details.map((detail) => (
					<div key={detail.name} className="relative px-5 lg:px-24 space-y-2">
						<h3 className="text-md font-bold">{detail.name}</h3>
						<p className="text-sm leading-6">{detail.description}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default PortfolioDetails;
