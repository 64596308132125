import featureImg from '../coreImg/pass-demo.jpeg';

const PassDetails = () => {
	const features = [
		{
			name: 'Goal',
			description: `To practice CSS more and create a real good looking UI`,
		},
		{ name: 'Back End', description: `No backend involved in this` },
		{ name: `Front End`, description: `ReactJs, HTML, Vanilla CSS` },
		{ name: `Challenges`, description: 'Customize form elements' },
		{
			name: 'Features',
			description: `Beautiful single page app, fully responsive, config & generate random string, incredibly fast`,
		},
		{ name: 'External Resources', description: 'Herokuapp for deployment' },
	];

	const details = [
		{
			name: 'Ideas',
			description: `I create new account to sign up for new service every week. I use the default suggestion from both Google Chrome and Apple Keychain. But for some occasions, these tools couldn't identify the password field to suggest one, so I have to actually type in the password. So it's nice to build a tool that can do it for me without having to think`,
		},
		{
			name: 'It should be easy...',
			description:
				"That's what I thought when I started it. Let's use custom alphabet nanoid to generate the string at will, and polish the UI they way I want, what's so hard about it?. Man, I was in for a world of pain. Not only did I find out customizing input elements is not as easy as customizing a button, or a text div, but I also found it incredibly hard to customize the range slider. I had to basically hide the native element of the input and build a 'fake' one to replace it, in order to make it look elegant. Lession learned, don't underestimate the custom form field",
		},
		{
			name: 'and the problem with nanoid',
			description: `The page allows users to configure how to construct the password, including upperscase, lowercase letters, numbers, or special symbols,a dn length. I used useState hook to store these input options and rerender the output password and changed. Everything worked beautifully until I touched the range slider input, it's used to change the length of the password. Whenever I slid it, the site stopped working. Every other config worked, including the add-minus buttons, at first I thought the state couldn't update that fast. I searched around and indeed you shouldn't update state too fast, but it can't be this bad. So after tweaking things, I found nanoid to be the bottleneck. So I came back to the basic, used math.random and it's solved.`,
		},
		{
			name: 'How about using a UI framework?',
			description: `I'm well aware of these frameworks out there that has beautifully created UI elements out of the box. But I figured, when starting out, it's more important for me to try building it myself and learn how it works instead of finishing it quick and skipping the basic. So I chose to do it the hard way, and it was all worth it.`,
		},
	];

	return (
		<div className="z-10 max-w-full relative overflow-y-auto pb-24 min-h-screen bg-neutral-900 text-neutral-200">
			<div className="bg-neutral-900 space-y-12">
				<div aria-hidden="true" className="relative">
					<img
						src={featureImg}
						alt=""
						className="w-full h-96 object-center object-cover"
					/>
					<div className="absolute inset-0 bg-gradient-to-t from-neutral-900" />
				</div>

				<div className="relative bg-neutral-900 px-5 lg:px-24">
					<div className="max-w-2xl mx-auto text-center lg:max-w-4xl">
						<h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
							Strong Password Generator
						</h2>
						<p className="mt-4">
							Quickly generate a strong random password from your options of
							choice.
						</p>
					</div>
					<dl className="mt-16 max-w-2xl mx-auto grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:max-w-none lg:grid-cols-3 lg:gap-x-8">
						{features.map((feature) => (
							<div key={feature.name} className="border-t border-gray-200 pt-4">
								<dt className="font-semibold">{feature.name}</dt>
								<dd className="mt-2 text-sm">{feature.description}</dd>
							</div>
						))}
					</dl>
					<div className="flex gap-2">
						<a
							className="flex w-1/2 space-x-2 mt-8 items-center justify-center cursor-pointer font-semibold px-4 py-2 text-sm rounded-lg text-white hover:text-white shadow-xs bg-blue-500 hover:shadow-sm"
							target="_blank"
							rel="noopener noreferrer"
							href="https://p.joen.me/"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								className="bi bi-link-45deg"
								viewBox="0 0 16 16"
							>
								<path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
								<path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
							</svg>
							<span>Visit live</span>
						</a>
						<a
							className="flex w-1/2 space-x-2 mt-8 items-center justify-center cursor-pointer font-semibold px-4 py-2 text-sm rounded-lg text-white hover:text-white shadow-xs bg-gray-500 hover:shadow-sm"
							target="_blank"
							rel="noopener noreferrer"
							href="https://github.com/Tungtono/pass-gen/"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								class="bi bi-github"
								viewBox="0 0 16 16"
							>
								<path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
							</svg>
							<span>Github</span>
						</a>
					</div>
				</div>
				{details.map((detail) => (
					<div key={detail.name} className="relative px-5 lg:px-24 space-y-2">
						<h3 className="text-md font-bold">{detail.name}</h3>
						<p className="text-sm leading-6">{detail.description}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default PassDetails;
