import listing from './coreImg/listing.jpeg';
import options from './coreImg/options.jpeg';
import results from './coreImg/results.jpeg';

const Others = () => {
	return (
		<div className="z-10 max-w-full relative overflow-y-auto box-border max-h-screen bg-neutral-900 text-neutral-200">
			<div className="mx-auto lg:w-1/2 lg:py-32 px-5 py-12">
				<h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
					Other stuff that I do 🤑
				</h2>
				<p className="leading-7 mt-6 tracking-[0.02rem]">
					I don't often define myself by the work I've done but I've built my
					past career in a variety of industries as a business professional.
					Being a life long learner and problem solver at heart has played a
					vital role in shaping my entrepreneurial journey. It urges me to
					always find ways fixing things up, or to keep learning and finding
					interesting things that matter to work on. I tried a lot of different
					things, then I got lucky 😌
				</p>
				<div className="relative mt-8">
					<div
						className="absolute inset-0 flex items-center"
						aria-hidden="true"
					>
						<div className="w-2/3 border-t border-dashed border-gray-400" />
					</div>
					<div className="relative flex justify-start">
						<span className="pr-2 bg-stone-900 tracking-[0.2rem] text-xs text-gray-400 uppercase">
							sell stuff
						</span>
					</div>
				</div>
				<div className="leading-7 mt-6 space-y-6 tracking-[0.02rem]">
					<p>
						In my sophomore year, I started a business selling personalized{' '}
						<a
							className="text-[#1f6feb] font-bold"
							target="_blank"
							rel="noopener noreferrer"
							href="https://vietpiczzle.com/"
						>
							jigsaw puzzle
						</a>
						. In retrospect, it was life changing as it built me a good
						foundation with lots of experiences building a profitable business
						from 0, and coincidentally, almost got me dropped out of school for
						not attending enough classes. It worked out kinda ok in the end,
						still got my Bachelor's Degree though.
					</p>
					<p>
						As a byproduct, the knowledge I gained even got me into{' '}
						<a
							className="text-[#1f6feb] font-bold"
							target="_blank"
							rel="noopener noreferrer"
							href="https://google.com/"
						>
							Google
						</a>{' '}
						as a digital marketing trainer for students in the emerging markets.{' '}
					</p>
					<p>
						I then spent a good amount of time working on a{' '}
						<a
							className="text-[#1f6feb] font-bold"
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.triip.me/"
						>
							travel platform
						</a>{' '}
						with a cool concept of connecting travellers with passionate local
						people, before moving on to build{' '}
						<a
							className="text-[#1f6feb] font-bold"
							target="_blank"
							rel="noopener noreferrer"
							href="https://tableplus.com/"
						>
							TablePlus
						</a>
						, a powerful database management tool, and had it used by hundreds
						of thousand developers. If you work with database as a dev, you
						probably heard of it somewhere. Those were good times.{' '}
					</p>
					<p>
						Most recently, I built{' '}
						<a
							className="text-[#1f6feb] font-bold"
							target="_blank"
							rel="noopener noreferrer"
							href="https://bocanvas.co/"
						>
							Bo Commerce
						</a>{' '}
						as a place for people to find and send the most thoughtful
						sentimental gifts to their beloved, serving hundreds of thousand
						customers.
					</p>
				</div>
				<div className="relative mt-8">
					<div
						className="absolute inset-0 flex items-center"
						aria-hidden="true"
					>
						<div className="w-2/3 border-t border-dashed border-gray-400" />
					</div>
					<div className="relative flex justify-start">
						<span className="pr-2 bg-stone-900 tracking-[0.2rem] text-xs text-gray-400 uppercase">
							design stuff
						</span>
					</div>
				</div>
				<p className="leading-7 mt-6 tracking-[0.02rem]">
					When building things from ground up, I had to wear multiple hats and
					do lots of different things myself early on, design is a huge part of
					it. So I've picked up design and developed quite an eye for it. Not
					calling myself a pro, but my years of working with{' '}
					<a
						className="text-[#1f6feb] font-bold"
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.sketch.com/"
					>
						Sketch
					</a>{' '}
					coupled with my obsession with customer experience optimization allow
					me to craft up a decent app design by myself that people will have a
					good time using.
				</p>
				<p className="leading-7 mt-6 tracking-[0.02rem] text-xs italic text-gray-400">
					Some conceptualized feature promo I've done recently for my upcoming
					hobby Shopify app project
				</p>
				<div className="flex mt-2 justify-items-start h-80 overflow-x: scroll overflow-auto box-border">
					<img
						src={listing}
						alt="listing"
						className="rounded-xl max-h-full self-center mx-1"
					></img>
					<img
						src={results}
						alt="results"
						className="rounded-xl max-h-full self-center mx-1"
					></img>
					<img
						src={options}
						alt="options"
						className="rounded-xl max-h-full self-center mx-1"
					></img>
				</div>
			</div>
		</div>
	);
};

export default Others;
