import postData from './blogListData';
import { NavLink } from 'react-router-dom';

const activeClassName =
	'z-10 flex mx-3 my-0.5 space-y-1 p-4 text-sm rounded-lg border-none py-2  bg-gray-800 text-white';
const inactiveClassName =
	'z-10 flex mx-3 my-0.5 space-y-1 p-4 text-sm rounded-lg border-none py-2 hover:bg-zinc-800 hover:text-white';

const BlogList = () => {
	return (
		<>
			{postData.map((item) => (
				<NavLink
					to={item.url}
					key={item.name}
					className={({ isActive }) =>
						isActive ? activeClassName : inactiveClassName
					}
				>
					<div className="flex flex-col justify-center space-y-1">
						<div className="font-semibold">{item.name}</div>
						<div className="text-gray-100 text-opacity-60 text-xs">
							{item.date}
						</div>
					</div>
				</NavLink>
			))}
		</>
	);
};

export default BlogList;
