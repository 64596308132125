const devNoteListData = [
	{
		name: 'Understand the underlying Javascript - event loop',
		url: '/dev/event-loop',
		category: 'Javascript',
		source: require('../../markdown/dev/event-loop.md'),
	},
	{
		name: 'Git essentials',
		url: '/dev/git',
		category: 'Git',
		source: require('../../markdown/dev/git.md'),
	},
	{
		name: 'Regular Expression',
		url: '/dev/regex',
		category: 'Regex',
		source: require('../../markdown/dev/regex.md'),
	},
	{
		name: 'Polyfill for Webpack 5',
		url: '/dev/polyfill',
		category: 'NodeJs',
		source: require('../../markdown/dev/polyfill.md'),
	},
	{
		name: 'ACID in database',
		url: '/dev/acid',
		category: 'Database',
		source: require('../../markdown/dev/acid.md'),
	},
	{
		name: 'Optimizing performance for large data set',
		url: '/dev/optimize-large-data',
		category: 'Javascript',
		source: require('../../markdown/dev/optimize-large-data.md'),
	},
	{
		name: 'Passing a function to setState instead of direct value',
		url: '/dev/setstate',
		category: 'React.js',
		source: require('../../markdown/dev/setstate.md'),
	},
	{
		name: 'Meaning of Underscores in Python',
		url: '/dev/python-underscore',
		category: 'Python',
		source: require('../../markdown/dev/python-underscore.md'),
	},
	{
		name: 'Mutability Vs Immutability',
		url: '/dev/mutability-vs-immutability',
		category: 'Javascript',
		source: require('../../markdown/dev/mutability-vs-immutability.md'),
	},
	{
		name: 'Class Component vs Functional Component',
		url: '/dev/class-component-vs-functional-component',
		category: 'ReactJS',
		source: require('../../markdown/dev/class-component-vs-functional-component.md'),
	},
	{
		name: 'Using .env to hide credentials',
		url: '/dev/using-env',
		category: 'Security',
		source: require('../../markdown/dev/using-env.md'),
	},
	{
		name: 'Functional Programming Jargon',
		url: '/dev/functional-programming-jargon',
		category: 'JavaScript',
		source: require('../../markdown/dev/functional-programming-jargon.md'),
	},
	{
		name: 'Flex vs Grid',
		url: '/dev/flex-vs-grid',
		category: 'CSS',
		source: require('../../markdown/dev/flex-vs-grid.md'),
	},
	{
		name: 'fetch() vs axios()',
		url: '/dev/fetch-axios',
		category: 'API, NodeJs',
		source: require('../../markdown/dev/fetch-axios.md'),
	},
	{
		name: 'filter() vs find()',
		url: '/dev/filter-find',
		category: 'JavaScript',
		source: require('../../markdown/dev/filter-find.md'),
	},
	{
		name: 'every() vs some()',
		url: '/dev/every-some',
		category: 'JavaScript',
		source: require('../../markdown/dev/every-some.md'),
	},
];

export default devNoteListData;
