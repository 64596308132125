const Read = () => {
	return (
		<div className="z-10 max-w-full relative overflow-y-scroll box-border max-h-screen bg-neutral-900 text-neutral-200">
			<div className="mx-auto lg:w-1/2 lg:py-32 px-5 py-12">
				<h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
					What's I'm reading ... WIP 🛠
				</h2>
				<p className="text-gray-200 leading-7 mt-6 tracking-[0.02rem]">
					I'm a tireless seeker of knowledge who is obsessed with learning about
					people, tech, and business. I try to read as often as possible. It
					keeps my mind sharp and freshens it with new ideas. Would be great to
					share them here but I'm still deciding how to.
				</p>
				<div className="relative mt-8">
					<div
						className="absolute inset-0 flex items-center"
						aria-hidden="true"
					>
						<div className="w-2/3 border-t border-dashed border-gray-400" />
					</div>
					<div className="relative flex justify-start">
						<span className="pr-2 bg-stone-900 tracking-[0.2rem] text-xs text-gray-400 uppercase">
							books
						</span>
					</div>
				</div>
				<p className="text-gray-200 leading-7 mt-6 tracking-[0.02rem]">
					Will probably be a list of books that allows people to comment on what
					they think about each of them too.
				</p>
				<div className="relative mt-8">
					<div
						className="absolute inset-0 flex items-center"
						aria-hidden="true"
					>
						<div className="w-2/3 border-t border-dashed border-gray-400" />
					</div>
					<div className="relative flex justify-start">
						<span className="pr-2 bg-stone-900 tracking-[0.2rem] text-xs text-gray-400 uppercase">
							online articles
						</span>
					</div>
				</div>
				<p className="text-gray-200 leading-7 mt-6 tracking-[0.02rem]">
					Not sure yet, but I'm thinking about building a chrome extension
					sending my browser bookmark here so when ever I find somthing
					interesting around the web, it'll be shared here auto matically.
					Imagine it's like a Pinterest pin button extension. It requires me to
					build a backend database for this which I'm leaning toward not to as
					not necessary 🤣
				</p>
				<div className="relative mt-8">
					<div
						className="absolute inset-0 flex items-center"
						aria-hidden="true"
					>
						<div className="w-2/3 border-t border-dashed border-gray-400" />
					</div>
					<div className="relative flex justify-start">
						<span className="pr-2 bg-stone-900 tracking-[0.2rem] text-xs text-gray-400 uppercase">
							and maybe podcast too
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Read;
