const postData = [
	{
		name: 'Sarcasm: The Invisible Martial Art of Dialogue',
		url: '/blog/sarcasm',
		date: 'May 28, 2023',
		source: require('../../markdown/blog/sarcasm.md'),
	},
	{
		name: 'My martial art class',
		url: '/blog/martial-art-class',
		date: 'March 20, 2018',
		source: require('../../markdown/blog/martial-art-class.md'),
	},
	{
		name: 'How we got featured on the front page of Hacker News… by accident',
		url: '/blog/hacker-news-featured',
		date: 'March 04, 2018',
		source: require('../../markdown/blog/hacker-news-featured.md'),
	},
	{
		name: 'How to avoid being bitten by ants when climbing a tree?',
		url: '/blog/ant-bite',
		date: 'October 29, 2017',
		source: require('../../markdown/blog/ant-bite.md'),
	},
	{
		name: 'Salty Diamond Rocket',
		url: '/blog/salty-diamond-rocket',
		date: 'October 29, 2017',
		source: require('../../markdown/blog/salty-diamond-rocket.md'),
	},
	{
		name: "What's up in Malaysia",
		url: '/blog/malaysia',
		date: 'July 28, 2017',
		source: require('../../markdown/blog/malaysia.md'),
	},
	{
		name: 'First experience in Malaysia',
		url: '/blog/first-malaysia',
		date: 'July 07, 2017',
		source: require('../../markdown/blog/first-malaysia.md'),
	},
	{
		name: 'My street philosophy',
		url: '/blog/street-philosphy',
		date: 'March 10, 2017',
		source: require('../../markdown/blog/street-philosphy.md'),
	},
	{
		name: '30 Days Challenge',
		url: '/blog/30day-challenge',
		date: 'February 17, 2017',
		source: require('../../markdown/blog/30day-challenge.md'),
	},

	{
		name: 'UX in life',
		url: '/blog/ux',
		date: 'August 25, 2016',
		source: require('../../markdown/blog/ux.md'),
	},
	{
		name: 'Meo: Age of extinction',
		url: '/blog/meo',
		date: 'June 17, 2016',
		source: require('../../markdown/blog/meo.md'),
	},
	{
		name: 'About start up',
		url: '/blog/startup',
		date: 'April 18, 2016',
		source: require('../../markdown/blog/startup.md'),
	},
	{
		name: 'Cold email, to get more than 50% response rate within 6 sentences',
		url: '/blog/cold-email',
		date: 'April 10, 2016',
		source: require('../../markdown/blog/cold-email.md'),
	},
	{
		name: 'Enchantment',
		url: '/blog/enchantment',
		date: 'December 31, 2014',
		source: require('../../markdown/blog/enchantment.md'),
	},
];

export default postData;
