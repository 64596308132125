import featureImg from '../coreImg/feedback-demo.jpg';

const FeedbackDetails = () => {
	const features = [
		{
			name: 'Goal',
			description: `Build a complete system with public portal, admin dashboard, and user anthentication`,
		},
		{
			name: 'Back End',
			description: `NodeJs, Express, Postgres, Auth0, express JWT`,
		},
		{ name: `Front End`, description: `ReactJs, HTML, TailwindCSS` },
		{
			name: `Challenges`,
			description: 'User Anthentication & Private API Authorization',
		},
		{
			name: 'Features',
			description: `Simple public portal to collect feedback and admin dashboard to work on them using private API, beautifully responsive`,
		},
		{
			name: 'External Resources',
			description:
				'Auth0 SDK, Vercel for deployment, Herokuapp for database hosting',
		},
	];

	const details = [
		{
			name: 'Ideas',
			description: `I set on a new challenge to see how fast I can craft up an MVP of a feedback collecting system that utilizes user authentication and server side authorization to build private API. Plus, it's a good chance to work with PostgreSQL.`,
		},
		{
			name: 'Coming up with the design',
			description:
				"I have used several issue trackers myself so I thought coming up with a decent design would be easy. That's true for the public portal, but for the admin dashboard, that took me a little longer. Finally I decided to go with multi column pattern as it's how most admin systems are, something we use everyday like email, and it just feels more natual",
		},
		{
			name: 'To build or to buy',
			description: `The main purpose of this project is to practice implementing user authentication. I had to research a lot on the topic, about best practices, frameworks, cookies or localStorage, etc. It was interesting. After talking to a few friends who are senior system architects, I decide to use external auth service. It has more real life usecase when most of projects out there are using 3rd party services. Between Firebase and Auth0, I went with Auth0 because they seem to be more focused on authentication & security`,
		},
		{
			name: "It's getting more complicated",
			description: `Any React application can start well until we start adding condition-based logic on top of it. Soon it can start getting worse upon adding granular checks for roles and permissions for user access. It went well for me until I got to figure out how to persist login status after refreshing my React app, and redirect to original page after logging in. The solutions were simple when I figured out, but were quite a challenge at the time.`,
		},
		{
			name: 'And the ultimate challenge',
			description: `Now it's time to do the most challenging task for me, doing server side authorization and build private API endpoints for the admin dashboard. I had never done this before and had no clue what to do as well. But I imagined instead of fetching data from a public API endpoint, now I have to pass along an authorization code inside the request, and add a middleware on the server to check for permission before returning data. With the help of Auth0's getAccessTokenSilently function on the client side and express-oauth2-jwt-bearer package for the server, it worked out beautifully.`,
		},
	];

	return (
		<div className="z-10 max-w-full relative overflow-y-auto pb-24 min-h-screen bg-neutral-900 text-neutral-200">
			<div className="bg-neutral-900 space-y-12">
				<div aria-hidden="true" className="relative">
					<img
						src={featureImg}
						alt=""
						className="w-full h-96 object-center object-cover"
					/>
					<div className="absolute inset-0 bg-gradient-to-t from-neutral-900" />
				</div>

				<div className="relative bg-neutral-900 px-5 lg:px-24">
					<div className="max-w-2xl mx-auto text-center lg:max-w-4xl">
						<h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
							Feedback Hub
						</h2>
						<p className="mt-4">
							Collect and manage feature request, track issues and bug reports,
							or both.
						</p>
					</div>
					<dl className="mt-16 max-w-2xl mx-auto grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:max-w-none lg:grid-cols-3 lg:gap-x-8">
						{features.map((feature) => (
							<div key={feature.name} className="border-t border-gray-200 pt-4">
								<dt className="font-semibold">{feature.name}</dt>
								<dd className="mt-2 text-sm">{feature.description}</dd>
							</div>
						))}
					</dl>
					<a
						className="flex space-x-2 mt-8 flex-none items-center justify-center cursor-pointer font-semibold px-4 py-2 text-sm rounded-lg text-white hover:text-white shadow-xs bg-blue-500 border border-blue-600 dark:border-blue-400 dark:border-opacity-50 hover:shadow-sm"
						target="_blank"
						rel="noopener noreferrer"
						href="https://f.joen.me/"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							className="bi bi-link-45deg"
							viewBox="0 0 16 16"
						>
							<path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
							<path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
						</svg>
						<span>Visit</span>
					</a>
					{/* <p className='text-xs italic mt-1'>*I set up a minimal server for this project and it sleeps after 30 minutes of inactivity. So when you access it first time, it'll likely take a little longer to get started, don't give up on it too soon*</p> */}
				</div>
				{details.map((detail) => (
					<div key={detail.name} className="relative px-5 lg:px-24 space-y-2">
						<h3 className="text-md font-bold">{detail.name}</h3>
						<p className="text-sm leading-6">{detail.description}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default FeedbackDetails;
