import { NavLink } from 'react-router-dom';

const data = [
	{
		name: 'Feedback Hub',
		description: 'Collect, manage feedback & issues',
		stack: 'Full Stack',
		url: '/code/feedback',
		img: require('../coreImg/feedback.webp'),
	},
	{
		name: 'Speed Test',
		description: 'Measure Internet speed the right way',
		stack: 'Front End',
		url: '/code/speed',
		img: require('../coreImg/speed.webp'),
	},
	{
		name: 'tini.one',
		description: 'Shorten a long ugly link to a beautiful little one',
		stack: 'Full Stack',
		url: '/code/url',
		img: require('../coreImg/url.webp'),
	},
	{
		name: 'joen.me',
		description: 'A minimalistic collection of my works & thoughts',
		stack: 'Front End',
		url: '/code/portfolio',
		img: require('../coreImg/portfolio.webp'),
	},
	{
		name: 'Password Generator',
		description: 'Instantly create a secure, random password',
		stack: 'Front End',
		url: '/code/pass',
		img: require('../coreImg/pass.webp'),
	},
	{
		name: 'Bookshelf',
		description: 'The good old CRUD app for beginners',
		stack: 'Full Stack',
		url: '/code/bookshelf',
		img: require('../coreImg/book.webp'),
	},
];

const activeClassName =
	'flex mx-3 my-1 space-y-1 px-4 py-4 text-sm rounded-xl border-none hover:bg-zinc-800 bg-gray-800 text-white';
const inactiveClassName =
	'flex mx-3 my-1 space-y-1 px-4 py-4 text-sm rounded-xl border-none hover:bg-zinc-800 text-neutral-300';

const CodeList = () => {
	return (
		<>
			{data.map((item) => (
				<NavLink
					to={item.url}
					key={item.name}
					className={({ isActive }) =>
						isActive ? activeClassName : inactiveClassName
					}
				>
					<img
						alt="NeuBible"
						src={item.img}
						className=" h-28 mr-4 self-center rounded-xl"
					/>
					<div className="space-y-2 mt-2">
						<div
							className={
								item.stack === 'Front End'
									? 'text-[0.6rem] text-black bg-yellow-300 w-fit rounded px-1.5 py-[0.1rem] uppercase font-black tracking-widest'
									: 'text-[0.6rem] text-black bg-green-500 w-fit rounded px-2 py-[0.1rem] uppercase font-black tracking-widest'
							}
						>
							{item.stack}
						</div>
						<div className="font-black text-[0.95rem]">{item.name}</div>
						<div className="text-sm leading-6 text-ellipsis overflow-hidden">
							{item.description}
						</div>
					</div>
				</NavLink>
			))}
		</>
	);
};

export default CodeList;
