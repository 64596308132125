import featureImg from '../coreImg/speed-demo.jpg';

const SpeedDetails = () => {
	const features = [
		{
			name: 'Goal',
			description: `To dig deeper into network connection & constructing network requests`,
		},
		{
			name: 'Back End',
			description: `None was needed, request data from external server and process all on client`,
		},
		{ name: `Front End`, description: `ReactJs, TailwindCSS, HTML` },
		{
			name: `Challenges`,
			description:
				'How to accurately measure Internet speed and return consistent results',
		},
		{
			name: 'Features',
			description: `Simple and intuitive 1 page to run the test on load, plus reset button to run the test again if needed`,
		},
		{
			name: 'External Resources',
			description: 'CloudFlare server for testing, Vercel for deployment',
		},
	];

	const details = [
		{
			name: 'Ideas',
			description: `I recently moved so I looked up some Internet deals to set up for the new house. As I was comparing packages from multiple ISPs with various speed offers, I had to test my current network to give a benchmark. So I thought it would be fun to build one tester.`,
		},
		{
			name: 'Getting started',
			description:
				"To proper measure the network, I have to have a proper understanding of the network, about time to first byte, DNS lookup, SSL handshake, etc. It's like deciphering a network request and deciding what will be counted towards server processing time and what not.",
		},
		{
			name: 'The right approach',
			description: `I've seen a few example speed tests but almost all of them are just very simple one off tests. Because Internet connection depends on various factors so it fluctuates a lot, this approach will not properly represent the overall speed as it might be super high or super low randomly. Besides, they normally measure it based on total time from sending request to fully loaded packages, this doesn't acknowledge server processing time and such, so it further makes the tests more inaccurate.`,
		},
		{
			name: 'Structure and implement the tests',
			description: `I decided that it has to be a lot of tests put together for an average value. But upon reading more about it, a median value sounds more reasonable. So I structured the app around 80 tests. 20 quick tests to measure latency, 30 tests of various sizes to measure download, and another 30 tests to measure upload.`,
		},
		{
			name: 'Dark theme',
			description: `This is the very first time that I built a dark/light theme toggle. Since the design is simple, this makes it much more easier getting around the switch, plus it's well supported within TailwindCSS.`,
		},
	];

	return (
		<div className="z-10 max-w-full relative overflow-y-auto pb-24 min-h-screen bg-neutral-900 text-neutral-200">
			<div className="bg-neutral-900 mb-12 space-y-12">
				<div aria-hidden="true" className="relative">
					<img
						src={featureImg}
						alt=""
						className="w-full h-96 object-center object-cover"
					/>
					<div className="absolute inset-0 bg-gradient-to-t from-neutral-900" />
				</div>

				<div className="relative bg-neutral-900 px-5 lg:px-24">
					<div className="max-w-2xl mx-auto text-center lg:max-w-4xl">
						<h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
							Internet Speed Test
						</h2>
						<p className="mt-4">
							A lightweight yet sophisticated speed monitor powered by a lot of
							thorough tests & calculations.
						</p>
					</div>
					<dl className="mt-16 max-w-2xl mx-auto grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:max-w-none lg:grid-cols-3 lg:gap-x-8">
						{features.map((feature) => (
							<div key={feature.name} className="border-t border-gray-200 pt-4">
								<dt className="font-semibold">{feature.name}</dt>
								<dd className="mt-2 text-sm">{feature.description}</dd>
							</div>
						))}
					</dl>
					<div className="flex gap-2">
						<a
							className="flex w-1/2 space-x-2 mt-8 items-center justify-center cursor-pointer font-semibold px-4 py-2 text-sm rounded-lg text-white hover:text-white shadow-xs bg-blue-500 hover:shadow-sm"
							target="_blank"
							rel="noopener noreferrer"
							href="https://s.joen.me/"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								className="bi bi-link-45deg"
								viewBox="0 0 16 16"
							>
								<path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
								<path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
							</svg>
							<span>Visit live</span>
						</a>
						<a
							className="flex w-1/2 space-x-2 mt-8 items-center justify-center cursor-pointer font-semibold px-4 py-2 text-sm rounded-lg text-white hover:text-white shadow-xs bg-gray-500 hover:shadow-sm"
							target="_blank"
							rel="noopener noreferrer"
							href="https://github.com/Tungtono/speedtest"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								class="bi bi-github"
								viewBox="0 0 16 16"
							>
								<path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
							</svg>
							<span>Github</span>
						</a>
					</div>
				</div>
				{details.map((detail) => (
					<div key={detail.name} className="relative px-5 lg:px-24 space-y-2">
						<h3 className="text-md font-bold">{detail.name}</h3>
						<p className="text-sm leading-6">{detail.description}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default SpeedDetails;
