import ReactMarkdown from 'react-markdown';
import { useEffect, useState } from 'react';
import devNoteListData from './devNoteListData';

const DevNoteDetails = (props) => {
	const [content, setContent] = useState('');
	const slug = Object.values(props.params);
	const post = devNoteListData.find((item) => item.url === `/dev/${slug[0]}`);

	useEffect(() => {
		fetch(post.source)
			.then((res) => res.text())
			.then((text) => setContent(text));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	return (
		<div className="z-10 grow overflow-y-auto box-border max-h-screen px-5 py-12 lg:px-24 lg:py-20 bg-neutral-900 text-neutral-200">
			<ReactMarkdown
				className="prose prose-invert break-words whitespace-normal"
				children={content}
			/>
		</div>
	);
};

export default DevNoteDetails;
