import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';

const activeClassName =
	'z-10 flex mx-3 my-0.5 space-y-1 p-4 text-sm rounded-lg border-none py-2 bg-gray-800 text-white';
const inactiveClassName =
	'z-10 flex mx-3 my-0.5 space-y-1 p-4 text-sm rounded-lg border-none py-2 hover:bg-zinc-800 hover:text-white';

const Eli5List = () => {
	const [rawData, setRawData] = useState([]);

	useEffect(() => {
		fetch(
			'https://www.reddit.com/r/explainlikeimfive/top/.json?limit=50&t=month'
		)
			.then((res) => res.json())
			.then((rawRes) => {
				setRawData(rawRes.data.children);
				console.log(rawData[0]);
			})
			.catch((err) => console.log(err));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{rawData.map((item) => (
				<NavLink
					to={item.data.id}
					key={item.data.id}
					className={({ isActive }) =>
						isActive ? activeClassName : inactiveClassName
					}
				>
					<div className="flex flex-col justify-center space-y-1">
						<div className="font-semibold">{item.data.title}</div>
						<div className="text-gray-100 text-opacity-60 text-xs">
							asked by {item.data.author}
						</div>
					</div>
				</NavLink>
			))}
		</>
	);
};

export default Eli5List;
