import featureImg from '../coreImg/url-demo.jpeg';

const UrlDetails = () => {
	const features = [
		{
			name: 'Goal',
			description: `To build a quick site handling external API using axios and practice CSS more`,
		},
		{
			name: 'Back End',
			description: `Initially used just rebrand.ly API but decided to build my own server using NodeJs, express, and MongoDB via mongoose`,
		},
		{
			name: `Front End`,
			description: `ReactJs, Vanilla CSS, HTML, React Router Dom, and axios`,
		},
		{
			name: `Challenges`,
			description:
				'Responsive design and custom input element, especially pseudo-classes and pseudo-elements',
		},
		{
			name: 'Features',
			description: `Simple and intuitive 1 page with URL input form, domain select and a submit button. Plus quick copy action on the shortened URL`,
		},
		{
			name: 'External Resources',
			description: 'rebrand.ly API, herokuapp for deployment',
		},
	];

	const details = [
		{
			name: 'Ideas',
			description: `I use rebrand.ly to shorten url all the time. So when using it, I looked at the UI and thought, if they provide an API, it should be quick and easy to put up a tool that I can use myself`,
		},
		{
			name: 'Setting it up',
			description:
				'It was kinda easy for me to build the first MVP, one text box and one submit button. Plugged in the API from rebrand.ly with not much to decorate other than sizing and position.',
		},
		{
			name: 'To building my own API',
			description: `After getting it worked, I thought, why not trying to build my own API. Shouldn't be that hard when I kinda had in mind how to handle saving data to the database. I used NodeJs, express, and MongoDB via mongoose to build the backend. I also used nanoid so generate a short 6 character slug and save it along with the long URL. Saving is done, but I had no idea how to handle the redirection when actually entering that short URL to the browser`,
		},
		{
			name: 'Handling the redirection',
			description: `I decided to handle it on the client side, so I came to React Router Dom for help. And my solution is quite simple, for every request, check the url path. If it's the homepage, render the main app component, otherwise, send a query to the database using the short slug and dynamically redirected to the oroginal long URL. It still sounds clumpsy to me as I believe it's not the optimal way to handle it, but it works.`,
		},
		{
			name: 'And more work on the UI',
			description: `I thought it was done, but no, my tiny little creative brain wanted me to decorate it and make it more elegant looking. So I played with customizing the UI more, thinking it would be so easy to finish them all. To my surprise, it took me 1 day to just get all the form elements to look consistent. In exchange, I also learned to work with pseudo-classes and pseudo-elements to build a custom tooltip that looks nice.`,
		},
	];

	return (
		<div className="z-10 max-w-full relative overflow-y-auto pb-24 min-h-screen bg-neutral-900 text-neutral-200">
			<div className="bg-neutral-900 mb-12 space-y-12">
				<div aria-hidden="true" className="relative">
					<img
						src={featureImg}
						alt=""
						className="w-full h-96 object-center object-cover"
					/>
					<div className="absolute inset-0 bg-gradient-to-t from-neutral-900" />
				</div>

				<div className="relative bg-neutral-900 px-5 lg:px-24">
					<div className="max-w-2xl mx-auto text-center lg:max-w-4xl">
						<h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
							tini.one
						</h2>
						<p className="mt-4">
							tini.one is a tiny but powerful tool to shorten a long ugly url
							down to a tiny beautiful little one.
						</p>
					</div>
					<dl className="mt-16 max-w-2xl mx-auto grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:max-w-none lg:grid-cols-3 lg:gap-x-8">
						{features.map((feature) => (
							<div key={feature.name} className="border-t border-gray-200 pt-4">
								<dt className="font-semibold">{feature.name}</dt>
								<dd className="mt-2 text-sm">{feature.description}</dd>
							</div>
						))}
					</dl>
					<a
						className="flex space-x-2 mt-8 flex-none items-center justify-center cursor-pointer font-semibold px-4 py-2 text-sm rounded-lg text-white hover:text-white shadow-xs bg-blue-500 border border-blue-600 dark:border-blue-400 dark:border-opacity-50 hover:shadow-sm"
						target="_blank"
						rel="noopener noreferrer"
						href="https://tini.one/"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							className="bi bi-link-45deg"
							viewBox="0 0 16 16"
						>
							<path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
							<path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
						</svg>
						<span>Visit</span>
					</a>
				</div>
				{details.map((detail) => (
					<div key={detail.name} className="relative px-5 lg:px-24 space-y-2">
						<h3 className="text-md font-bold">{detail.name}</h3>
						<p className="text-sm leading-6">{detail.description}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default UrlDetails;
