import { useState, useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import CodeList from './CodeList';
import PortfolioDetails from './PortfolioDetails';
import PassDetails from './PassDetails';
import UrlDetails from './UrlDetails';
import BookDetails from './BookDetails';
import FeedbackDetails from './FeedbackDetails';
import SpeedDetails from './SpeedDetails';

const Work = () => {
	const [emptyState, setEmptyState] = useState(false);

	const emptyClassName =
		'z-10 absolute overflow-y-auto lg:relative flex flex-col w-full lg:w-80 shrink-0 bg-stone-900 border-r border-neutral-800 pb-10 transition duration-200 ease-in-out max-h-screen min-h-screen transform translate-x-0';
	const notEmptyClassName =
		'absolute overflow-y-auto lg:relative flex flex-col w-80 shrink-0 bg-stone-900 border-r border-neutral-800 pb-10 transition duration-200 ease-in-out -translate-x-full max-h-screen min-h-screen transform lg:translate-x-0';

	const EmptyWork = () => {
		useEffect(() => {
			setEmptyState(true);
		}, []);
		return (
			<div className="w-full bg-neutral-900 flex-col justify-center items-center flex-1 relative overflow-y-auto box-border min-h-screen">
				<div className="bg-empty-code bg-top h-1/2 w-full"></div>
				<div className="text-gray-400 space-y-8 mx-12">
					<h2 className="text-center text-3xl font-extrabold tracking-tight sm:text-4xl">
						Hobby Projects
					</h2>
					<p className="mx-24 text-center">
						Things I've built from scratch to horn my skills. Select one project
						on the left to see details.
					</p>
				</div>
			</div>
		);
	};

	const data = {
		portfolio: <PortfolioDetails />,
		pass: <PassDetails />,
		bookshelf: <BookDetails />,
		url: <UrlDetails />,
		feedback: <FeedbackDetails />,
		speed: <SpeedDetails />,
	};
	const params = useParams();

	const slug = Object.values(params);

	return (
		<div className="flex w-full h-screen">
			<div className={emptyState ? emptyClassName : notEmptyClassName}>
				<div className="sticky items-center space-x-2 mx-3 px-4 py-3 mb-1.5 text-sm border-none hidden lg:flex">
					<span className="font-bold text-neutral-300 text-sm">
						What I've built
					</span>
				</div>
				<CodeList />
			</div>
			<Routes>
				<Route exact path="/" element={<EmptyWork />} />
				<Route exact path="/:id" element={data[slug[0]]} />
			</Routes>
		</div>
	);
};

export default Work;
