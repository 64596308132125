import { NavLink } from 'react-router-dom';

const Nav = (props) => {
	const handleClick = () => {
		props.togglePhoneNav();
	};

	let activeClassName =
		'flex flex-1 mx-3 my-0.5 space-x-2 px-4 py-2 text-sm rounded-lg border-none bg-[#1f6feb] hover:bg-zinc-800 hover:text-white text-white';

	let inactiveClassName =
		'flex flex-1 mx-3 my-0.5 space-x-2 px-4 py-2 text-sm rounded-lg border-none hover:bg-zinc-800 hover:text-white';

	return (
		<>
			<div
				className={
					props.showNav
						? 'z-50 absolute lg:relative translate-x-0 transition-all ease-in duration-200 border-r border-neutral-800'
						: 'z-50 absolute lg:relative -translate-x-full lg:translate-x-0 transition-all ease-in duration-200 border-r border-gray-800'
				}
			>
				<nav className="overflow-y-auto flex h-full min-h-screen max-h-screen flex-col w-52 shrink-0 bg-stone-900">
					<div className="flex items-center space-x-2 px-4 py-3 mb-1.5 text-sm border-none">
						<button
							type="button"
							className="text-neutral-300 lg:hidden hover:text-white"
							onClick={handleClick}
						>
							<span className="sr-only">Navigation</span>
							<svg
								className="h-8 w-8 hover:bg-neutral-700 rounded-lg"
								viewBox="-9 -11 29 30"
								fill="currentColor"
							>
								<path
									d="M0 0L10 10M10 0L0 10"
									fill="none"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
								></path>
							</svg>
						</button>
						<span className="font-extrabold text-neutral-300 text-sm">
							Joe Nguyen
						</span>
					</div>
					<div className="mb-2">
						<ul onClick={handleClick}>
							<li className="flex text-neutral-300">
								<NavLink
									to="/"
									className={({ isActive }) =>
										isActive ? activeClassName : inactiveClassName
									}
								>
									<div className="mr-2">
										<svg
											className="h-5 w-5"
											viewBox="-4 -2 24 24"
											fill="currentColor"
										>
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
												strokeWidth="0"
												strokeLinecap="round"
											></path>
										</svg>
									</div>
									<span className="font-semibold">Home</span>
								</NavLink>
							</li>
						</ul>
					</div>
					<div className="mb-4">
						{' '}
						{/*What I do*/}
						<h4 className="mx-3 my-0.5 px-4 pt-4 pb-2 text-xs text-neutral-400">
							Do
						</h4>
						<ul onClick={handleClick}>
							<li className="flex text-neutral-300">
								<NavLink
									to="/code"
									className={({ isActive }) =>
										isActive ? activeClassName : inactiveClassName
									}
								>
									<div className="mr-2">
										<svg
											className="h-5 w-5"
											viewBox="-4 -4 23 23"
											fill="currentColor"
										>
											<path d="M0 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3zm9.5 5.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zm-6.354-.354a.5.5 0 1 0 .708.708l2-2a.5.5 0 0 0 0-.708l-2-2a.5.5 0 1 0-.708.708L4.793 6.5 3.146 8.146z" />
										</svg>
									</div>
									<span className="font-semibold">Code</span>
								</NavLink>
							</li>
							<li className="flex text-neutral-300">
								<NavLink
									to="/others"
									className={({ isActive }) =>
										isActive ? activeClassName : inactiveClassName
									}
								>
									<div className="mr-2">
										<svg
											className="h-5 w-5"
											viewBox="-4 -4 23 23"
											fill="currentColor"
										>
											<path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
											<path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
										</svg>
									</div>
									<span className="font-semibold">Other stuff</span>
								</NavLink>
							</li>
						</ul>
					</div>
					<div className="mb-4">
						{' '}
						{/*What I write*/}
						<h4 className="mx-3 my-0.5 px-4 pt-4 pb-2 text-xs text-neutral-400">
							Write
						</h4>
						<ul onClick={handleClick}>
							<li className="flex text-neutral-300">
								<NavLink
									to="/dev"
									className={({ isActive }) =>
										isActive ? activeClassName : inactiveClassName
									}
								>
									<div className="mr-2">
										<svg
											className="h-5 w-5"
											viewBox="-4 -4 23 23"
											fill="currentColor"
										>
											<path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6.646 7.646a.5.5 0 1 1 .708.708L5.707 10l1.647 1.646a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708l2-2zm2.708 0 2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L10.293 10 8.646 8.354a.5.5 0 1 1 .708-.708z" />
										</svg>
									</div>
									<span className="font-semibold">Dev notes</span>
								</NavLink>
							</li>
							<li className="flex text-neutral-300">
								<NavLink
									to="/blog"
									className={({ isActive }) =>
										isActive ? activeClassName : inactiveClassName
									}
								>
									<div className="mr-2">
										<svg
											className="h-5 w-5"
											viewBox="-4 -4 23 23"
											fill="currentColor"
										>
											<path d="M9.167 4.5a1.167 1.167 0 1 1-2.334 0 1.167 1.167 0 0 1 2.334 0Z" />
											<path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM1 8a7 7 0 0 1 7-7 3.5 3.5 0 1 1 0 7 3.5 3.5 0 1 0 0 7 7 7 0 0 1-7-7Zm7 4.667a1.167 1.167 0 1 1 0-2.334 1.167 1.167 0 0 1 0 2.334Z" />
										</svg>
									</div>
									<span className="font-semibold">Life</span>
								</NavLink>
							</li>
						</ul>
					</div>
					<div className="mb-4">
						{' '}
						{/*What I learn*/}
						<h4 className="mx-3 my-0.5 px-4 pt-4 pb-2 text-xs text-neutral-400">
							Learn
						</h4>
						<ul onClick={handleClick}>
							<li className="flex text-neutral-300">
								<NavLink
									to="/read"
									className={({ isActive }) =>
										isActive ? activeClassName : inactiveClassName
									}
								>
									<div className="mr-2">
										<svg
											className="h-5 w-5"
											viewBox="-4 -4 23 23"
											fill="currentColor"
										>
											<path d="M8.5 2.687c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
										</svg>
									</div>
									<span className="font-semibold">Reading</span>
								</NavLink>
							</li>
							<li className="flex text-neutral-300">
								<NavLink
									to="/eli5"
									className={({ isActive }) =>
										isActive ? activeClassName : inactiveClassName
									}
								>
									<div className="mr-2">
										<svg
											className="h-5 w-5"
											viewBox="-4 -4 23 23"
											fill="currentColor"
										>
											<path d="M14 10a6 6 0 0 1-12 0C2 5.686 5 0 8 0s6 5.686 6 10z" />
										</svg>
									</div>
									<span className="font-semibold">eli5</span>
								</NavLink>
							</li>
							<li className="flex text-neutral-300">
								<NavLink
									to="/bookmarks"
									className={({ isActive }) =>
										isActive ? activeClassName : inactiveClassName
									}
								>
									<div className="mr-2">
										<svg
											className="h-5 w-5"
											viewBox="-4 -4 24 24"
											fill="currentColor"
										>
											<path d="M2 15.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v13.5zM8 4.41c1.387-1.425 4.854 1.07 0 4.277C3.146 5.48 6.613 2.986 8 4.412z" />
										</svg>
									</div>
									<span className="font-semibold">Bookmarks</span>
								</NavLink>
							</li>
						</ul>
					</div>
					<div className="mb-4">
						{' '}
						{/*Contact*/}
						<h4 className="mx-3 my-0.5 px-4 pt-4 pb-2 text-xs text-neutral-300">
							Contact
						</h4>
						<ul onClick={handleClick}>
							<li className="flex text-neutral-300">
								<NavLink
									to="/contact"
									className={({ isActive }) =>
										isActive ? activeClassName : inactiveClassName
									}
								>
									<div className="mr-2">
										<svg
											className="h-5 w-5"
											viewBox="-4 -4 23 23"
											fill="currentColor"
										>
											<path d="M11 8h2V6h-2v2Z" />
											<path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm8.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7ZM2 5.5a.5.5 0 0 0 .5.5H6a.5.5 0 0 0 0-1H2.5a.5.5 0 0 0-.5.5ZM2.5 7a.5.5 0 0 0 0 1H6a.5.5 0 0 0 0-1H2.5ZM2 9.5a.5.5 0 0 0 .5.5H6a.5.5 0 0 0 0-1H2.5a.5.5 0 0 0-.5.5Zm8-4v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5Z" />
										</svg>
									</div>
									<span className="font-semibold">Contact form</span>
								</NavLink>
							</li>
							<li className="flex text-neutral-300">
								<a
									className="flex flex-1 mx-3 my-0.5 py-2 px-4 text-sm rounded-lg border-none bg-black hover:bg-zinc-800"
									href="mailto:hi@joen.me"
								>
									<div className="flex flex-1 items-center space-x-2">
										<div className="mr-2">
											<svg
												className="h-5 w-5"
												viewBox="-4 -4 23 23"
												fill="currentColor"
											>
												<path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555l-4.2 2.568a2.785 2.785 0 0 0-.051-.105c-.666-1.3-2.363-1.917-3.699-1.25-1.336-.667-3.033-.05-3.699 1.25l-.05.105L.05 3.555ZM11.584 8.91a4.694 4.694 0 0 1-.073.139L16 11.8V4.697l-4.003 2.447c.027.562-.107 1.163-.413 1.767Zm-4.135 3.05c-1.048-.693-1.84-1.39-2.398-2.082L.19 12.856A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144L10.95 9.878c-.559.692-1.35 1.389-2.398 2.081L8 12.324l-.551-.365ZM4.416 8.91c-.306-.603-.44-1.204-.413-1.766L0 4.697v7.104l4.49-2.752a4.742 4.742 0 0 1-.074-.138Z" />
												<path d="M8 5.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z" />
											</svg>
										</div>
										<span className="font-semibold text-sm">Direct Email</span>
									</div>
									<div className="ml-auto">
										<svg
											className="h-5 w-5"
											viewBox="-4 -4 23 23"
											fill="currentColor"
										>
											<path
												fillRule="evenodd"
												d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
											/>
											<path
												fillRule="evenodd"
												d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
											/>
										</svg>
									</div>
								</a>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		</>
	);
};

export default Nav;
