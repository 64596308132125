import { Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import Nav from './Nav';
import Home from './Home';
import Blog from './blog/Blog';
import Code from './code/Code';
import PhoneNav from './PhoneNav';
import Bookmark from './bookmarks/Bookmarks';
import ContactForm from './ContactForm';
import Others from './Others';
import DevNote from './dev/DevNote';
import Eli5 from './eli5/Eli5';
import Read from './Read';

function App() {
	const [showNav, setShowNav] = useState(false);

	const toggleNav = () => {
		setShowNav((prevShowNav) => !prevShowNav);
		console.log(showNav);
	};

	return (
		<div className="flex h-full min-h-screen w-full bg-neutral-900">
			<Nav showNav={showNav} togglePhoneNav={toggleNav} />
			<div className="flex flex-col w-full">
				<PhoneNav togglePhoneNav={toggleNav} />
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route path="/code/*" element={<Code />} />
					<Route path="/others" element={<Others />} />
					<Route path="/dev/*" element={<DevNote />} />
					<Route path="/eli5/*" element={<Eli5 />} />
					<Route path="/blog/*" element={<Blog />} />
					<Route path="/read" element={<Read />} />
					<Route path="/bookmarks/*" element={<Bookmark />} />
					<Route path="/contact" element={<ContactForm />} />
				</Routes>
			</div>
		</div>
	);
}

export default App;
