const PhoneNav = (props) => {
	const handleClick = () => {
		props.togglePhoneNav();
		console.log(props);
	};

	return (
		<div className="flex relative items-center border-b border-neutral-800 lg:hidden">
			<div className="flex items-center space-x-2 px-4 py-3 text-sm border-none">
				<button
					type="button"
					className="text-slate-300 lg:hidden hover:text-white"
					onClick={handleClick}
				>
					<span className="sr-only">Navigation</span>
					<svg
						className="h-8 w-8 hover:bg-neutral-700 rounded-lg"
						viewBox="-4.5 -5 25 25"
						fill="currentColor"
					>
						<path
							fillRule="evenodd"
							d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
						/>
					</svg>
				</button>
				<span className="font-bold text-white text-sm">Joe Nguyen</span>
			</div>
		</div>
	);
};

export default PhoneNav;
