import { useEffect, useState } from 'react';

const Eli5Details = (props) => {
	const [rawQuestion, setRawQuestion] = useState([]);
	const [rawComment, setRawComment] = useState([]);

	const slug = Object.values(props.params);
	const targetPost = `https://www.reddit.com/r/explainlikeimfive/comments/${slug[0]}/.json?sort=top&limit=1`;

	useEffect(() => {
		fetch(targetPost)
			.then((res) => res.json())
			.then((rawRes) => {
				setRawQuestion(rawRes[0].data.children);
				setRawComment(rawRes[1].data.children);
			})
			.catch((err) => console.log(err));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	return (
		<div className="z-10 grow overflow-y-auto box-border max-h-screen px-5 py-12 lg:px-24 lg:py-20 bg-neutral-900 text-neutral-200">
			<div className="break-words whitespace-normal">
				{rawQuestion.map((item) => {
					return (
						<div
							className="p-0 border-b space-y-3 pb-6 border-gray-200 border-dashed"
							key={item.data.id}
						>
							<p className="font-bold my-0">Question: {item.data.title}</p>
							<div className="flex space-x-1.5 text-sm">
								<p className=" m-0">Asked by</p>
								<p className="font-bold m-0">{item.data.author}</p>
							</div>
							<div className="flex space-x-1.5 text-sm">
								<p className=" my-0">Upvoted</p>
								<p className="font-bold my-0">{item.data.ups}</p>
								<p className=" my-0">times</p>
							</div>
						</div>
					);
				})}
				{rawComment.map((comment) => {
					return (
						comment.kind === 't1' && (
							<div className="pt-6 space-y-3" key={comment.data.id}>
								<div className="flex space-x-1.5 text-sm">
									<p className="my-0">Top answer by</p>
									<p className="font-bold my-0">{comment.data.author}</p>
								</div>
								<div className="break-words box-border">
									{comment.data.body}
								</div>
								<div className="flex space-x-1.5 text-sm">
									<p className=" my-0">Upvoted</p>
									<p className="font-bold my-0">{comment.data.ups}</p>
									<p className=" my-0">times</p>
								</div>
							</div>
						)
					);
				})}
				<a
					className="flex space-x-2 mt-8 flex-none items-center justify-center cursor-pointer font-semibold px-4 py-2 text-sm rounded-lg text-white hover:text-white shadow-xs bg-blue-500 border border-blue-600 dark:border-blue-400 dark:border-opacity-50 hover:shadow-sm"
					target="_blank"
					rel="noopener noreferrer"
					href={`https://www.reddit.com/r/explainlikeimfive/comments/${slug[0]}`}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						fill="currentColor"
						className="bi bi-link-45deg"
						viewBox="0 0 16 16"
					>
						<path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
						<path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
					</svg>
					<span>Visit the original discussion</span>
				</a>
			</div>
		</div>
	);
};

export default Eli5Details;
