import { NavLink } from 'react-router-dom';

const Home = () => {
	return (
		<div className="z-10 max-w-full relative overflow-y-auto box-border min-h-full bg-neutral-900 text-neutral-200">
			<div className="mx-auto lg:w-1/2 lg:py-32 px-5 py-12">
				<h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
					Hi there 👋
				</h2>
				<p className="leading-7 mt-6 tracking-[0.02rem]">
					I'm Joe, a <strong>software engineer</strong> in 🇨🇦
				</p>
				<p className="leading-7 mt-6 tracking-[0.02rem]">
					I spend most of my time experimenting with HTML/CSS and Javascript
					(React, NodeJs), or dabbling with Python, to build products that
					delight. See{' '}
					<NavLink to="/code" className="text-[#1f6feb] font-bold">
						some projects
					</NavLink>{' '}
					I built for fun.
				</p>
				<h3 className="text-lg font-extrabold mt-8">start with why...</h3>
				<p className="leading-7 mt-4">
					I'm genuinely curious about how things work, I enjoy doing things that
					give me the pleasure of figuring things out and challenge me to
					constantly learn something new. That's pretty much what software
					engineering is and why I love it.
				</p>
				<p className="leading-7 mt-4">
					If you like what you see and want to have a chat, drop me a message at{' '}
					<a className="text-[#1f6feb] font-bold" href="mailto:hi@joen.me">
						hi@joen.me
					</a>
					. I'd love to connect.
				</p>
			</div>
		</div>
	);
};

export default Home;
