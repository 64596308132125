import { useState, useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import BookmarkDetails from './BookmarkDetails';
import BookmarkList from './BookmarkList';

const Bookmark = () => {
	const [emptyState, setEmptyState] = useState(false);

	const EmptyBookmark = () => {
		useEffect(() => {
			setEmptyState(true);
		}, []);
		return (
			<div className="w-full flex-col justify-center items-center flex-1 relative overflow-y-auto box-border min-h-screen bg-neutral-900">
				<div className="bg-empty-pattern bg-top h-1/2 w-full"></div>
				<div className="text-gray-400 space-y-8 mx-12">
					<h2 className="text-center text-3xl font-extrabold tracking-tight sm:text-4xl">
						Favorite Blogs
					</h2>
					<p className="mx-24 text-center">
						People around the web that I found interesting, I saved their blogs
						here to read once in a while, and re-read if needed. Check out a few
						to see if you like them too.
					</p>
				</div>
			</div>
		);
	};

	let params = useParams();

	const emptyClassName =
		'z-10 absolute overflow-y-auto lg:relative flex flex-col w-full lg:w-80 shrink-0 bg-stone-900 text-neutral-200 border-r border-neutral-800 pb-10 transition duration-200 ease-in-out max-h-screen min-h-screen transform translate-x-0';
	const notEmptyClassName =
		'absolute overflow-y-auto lg:relative flex flex-col w-80 shrink-0 bg-stone-900 text-neutral-200 border-r border-neutral-800 pb-10 transition duration-200 ease-in-out -translate-x-full max-h-screen min-h-screen transform lg:translate-x-0';

	return (
		<div className="flex w-full h-screen">
			<div className={emptyState ? emptyClassName : notEmptyClassName}>
				<div className="sticky items-center space-x-2 mx-3 px-4 py-3 mb-1.5 text-sm border-none hidden lg:flex">
					<span className="font-bold">Bookmarks</span>
				</div>
				<BookmarkList />
			</div>
			<Routes>
				<Route path="/" element={<EmptyBookmark />} />
				<Route path="/:id" element={<BookmarkDetails params={params} />} />
			</Routes>
		</div>
	);
};

export default Bookmark;
