import ReactMarkdown from 'react-markdown';
import { useEffect, useState } from 'react';
import postData from './blogListData';

const BlogDetails = (props) => {
	const [content, setContent] = useState('');
	const slug = Object.values(props.params);
	const post = postData.find((item) => item.url === `/blog/${slug[0]}`);

	useEffect(() => {
		fetch(post.source)
			.then((res) => res.text())
			.then((text) => setContent(text));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	return (
		<div className="prose prose-invert z-10 max-w-full relative overflow-y-auto box-border max-h-screen px-5 py-12 lg:px-24 lg:py-20 bg-neutral-900 text-neutral-200">
			<ReactMarkdown children={content} />
		</div>
	);
};

export default BlogDetails;
