import { useState, useEffect } from 'react';
import Eli5Details from './Eli5Details';
import Eli5List from './Eli5List';
import { Route, Routes, useParams } from 'react-router-dom';

const Eli5 = () => {
	const [emptyState, setEmptyState] = useState(false);

	const EmptyEli5 = () => {
		useEffect(() => {
			setEmptyState(true);
		}, []);
		return (
			<div className="w-full flex-col justify-center items-center flex-1 relative overflow-y-auto box-border min-h-screen bg-neutral-900">
				<div className="bg-empty-pattern bg-top h-1/2 w-full"></div>
				<div className="text-gray-400 space-y-8 mx-12">
					<h2 className="text-center text-3xl font-extrabold tracking-tight sm:text-4xl">
						explain like I'm five
					</h2>
					<p className="mx-24 text-center">
						I really love this{' '}
						<a
							className="text-[#1f6feb] font-bold"
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.reddit.com/r/explainlikeimfive/"
						>
							eli5 subreddit
						</a>{' '}
						where people try to explain things in very simple terms. It helps me
						a lot on my learning journey. Whenever I find something tricky to
						understand, I'll also try eli5 and it's super easy to remember the
						concept. I fetch top 50 eli5 this month from reddit, sometimes it's
						fun to read too
					</p>
				</div>
			</div>
		);
	};

	let params = useParams();

	const emptyClassName =
		'z-10 absolute overflow-y-auto lg:relative flex flex-col w-full lg:w-80 shrink-0 bg-stone-900 border-r border-neutral-800 pb-10 transition duration-200 ease-in-out max-h-screen min-h-screen transform translate-x-0 bg-stone-900 text-neutral-300';
	const notEmptyClassName =
		'absolute overflow-y-auto lg:relative flex flex-col w-80 shrink-0 bg-stone-900 border-r border-neutral-800 pb-10 transition duration-200 ease-in-out -translate-x-full max-h-screen min-h-screen transform lg:translate-x-0 bg-stone-900 text-neutral-300';

	return (
		<div className="flex w-full h-screen">
			<div className={emptyState ? emptyClassName : notEmptyClassName}>
				<div className="sticky items-center space-x-2 mx-3 px-4 py-3 mb-1.5 text-sm border-none hidden lg:flex">
					<span className="font-bold text-sm">Explain like I'm five</span>
				</div>
				<Eli5List />
			</div>
			<Routes>
				<Route path="/" element={<EmptyEli5 />} />
				<Route path="/:id" element={<Eli5Details params={params} />} />
			</Routes>
		</div>
	);
};

export default Eli5;
