import { useState } from 'react';
import bookmarkListData from './BookmarkListData';

const BookmarkDetails = (props) => {
	const [show, setShow] = useState(true);
	const showClassName =
		'absolute opacity-100 transition ease-in duration-300 shadow-md right-4 bottom-20 lg:top-1/2  bg-slate-300 rounded-lg max-h-12 flex justify-center items-center p-2 space-x-2';
	const hideClassName =
		'absolute opacity-0 transition ease-in duration-300 right-4 bottom-20 lg:top-1/2  bg-slate-300 rounded-lg max-h-12 flex justify-center items-center p-2 space-x-2';

	const slug = Object.values(props.params);
	const bookmarkSite = bookmarkListData.find(
		(item) => item.slug === `/bookmarks/${slug[0]}`
	);

	console.log(bookmarkSite);

	const handleClick = (e) => {
		e.preventDefault();
		setShow(false);
	};

	return (
		<div className="w-full h-full z-10 bg-black relative">
			<iframe
				title={bookmarkSite.name}
				src={bookmarkSite.linkTo}
				height="100%"
				width="100%"
			/>
			<a
				className={show ? showClassName : hideClassName}
				href={bookmarkSite.linkTo}
				target="_blank"
				rel="noopener noreferrer"
			>
				<div className="w-40 text-xs leading-4">
					If not rendered properly, click here to visit the original
				</div>
				<button onClick={(e) => handleClick(e)}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						fill="currentColor"
						className="bi bi-x-square-fill"
						viewBox="0 0 16 16"
					>
						<path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
					</svg>
				</button>
			</a>
		</div>
	);
};

export default BookmarkDetails;
