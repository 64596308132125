import { useState } from 'react';

const ContactForm = () => {
	const [contactInfo, setContactInfo] = useState({
		firstName: '',
		lastName: '',
		email: '',
		message: '',
	});

	const [submitted, setSubmitted] = useState(false);

	const handleChange = (e) => {
		const tempContactInfo = { ...contactInfo };
		tempContactInfo[e.target.name] = e.target.value;
		setContactInfo(tempContactInfo);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		let url =
			'https://api.mailmodo.com/api/v1/triggerTemplateEmail/t/ffbe9015-1814-4590-9872-f106f9c170dd';

		let data = {
			toEmail: 'tung.vpz@gmail.com',
			senderEmail: 'hi@joen.dev',
			subject: `New contact from web joen ${contactInfo.firstName} ${contactInfo.lastName}`,
			senderName: `Joe`,
			replyTo: `${contactInfo.email}`,
			data: {
				message: `${contactInfo.message} from ${contactInfo.email}, name is ${contactInfo.firstName} ${contactInfo.lastName}`,
			},
			campaignName: 'Contact',
		};

		let options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				mmApiKey: process.env.REACT_APP_API_KEY,
			},
			body: JSON.stringify(data),
		};

		fetch(url, options)
			.then((res) => res.json())
			.then((data) => setSubmitted(true))
			.catch((err) => console.error('error:' + err));
	};

	return (
		<div className="z-10 max-w-full relative overflow-y-auto box-border max-h-screen bg-neutral-900 text-neutral-200 text-sm">
			<div className="mx-auto lg:w-1/2 lg:py-32 px-5 py-12">
				<h2 className="text-3xl font-extrabold sm:text-4xl">Hi again 👋</h2>
				<p className="my-6">
					Glad you found this. I'd love to have a chat with the like-minded
					fellows!
				</p>
				<p className="my-6">
					Please send me an email to{' '}
					<a className="text-[#1f6feb]" href="mailto:hi@joen.me">
						hi@joen.me
					</a>{' '}
					or use the form below:
				</p>
				<form
					onSubmit={(e) => handleSubmit(e)}
					className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6"
				>
					<div>
						<label name="firstName" className="block">
							First name
						</label>
						<div className="mt-1">
							<input
								type="text"
								name="firstName"
								id="firstName"
								required
								autoComplete="given-name"
								className="py-3 px-4 block w-full border-gray-300 rounded-lg text-gray-900"
								onChange={(e) => handleChange(e)}
							/>
						</div>
					</div>
					<div>
						<label name="lastName" className="block">
							Last name
						</label>
						<div className="mt-1">
							<input
								type="text"
								name="lastName"
								id="lastName"
								autoComplete="family-name"
								className="py-3 px-4 block w-full border-gray-300 rounded-lg text-gray-900"
								onChange={(e) => handleChange(e)}
							/>
						</div>
					</div>
					<div className="sm:col-span-2">
						<label htmlFor="email" className="block">
							Email
						</label>
						<div className="mt-1">
							<input
								id="email"
								name="email"
								type="email"
								autoComplete="email"
								required
								className="py-3 px-4 block w-full text-gray-900 border-gray-300 rounded-lg"
								onChange={(e) => handleChange(e)}
							/>
						</div>
					</div>
					<div className="sm:col-span-2">
						<label htmlFor="message" className="block">
							Message
						</label>
						<div className="mt-1">
							<textarea
								id="message"
								name="message"
								required
								rows={4}
								className="py-3 px-4 block w-full text-gray-900 border border-gray-300 rounded-lg"
								onChange={(e) => handleChange(e)}
							/>
						</div>
					</div>
					<div className="sm:col-span-2">
						<button
							type="submit"
							className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-lg shadow-sm text-white text-bold bg-[#1f6feb] hover:bg-[#185ec9]"
						>
							Let's talk
						</button>
					</div>
				</form>
				<p
					className={
						submitted
							? 'mt-4 text-green-600 bg-green-200 p-3 rounded-lg text-center'
							: 'hidden mt-4 text-green-600 bg-green-200 p-3 rounded-lg text-center'
					}
				>
					Submitted sucessfully! I'll be in touch soon. Thanks!
				</p>
			</div>
		</div>
	);
};

export default ContactForm;
