const bookmarkListData = [
	{
		name: 'Wait but why',
		linkTo: 'https://waitbutwhy.com/',
		slug: '/bookmarks/1',
	},
	{
		name: "Seth's Blog",
		linkTo: 'https://seths.blog/',
		slug: '/bookmarks/2',
	},
	{
		name: 'Patrick Collison',
		linkTo: 'https://patrickcollison.com/',
		slug: '/bookmarks/3',
	},
	{
		name: 'First Round Review',
		linkTo: 'https://review.firstround.com/',
		slug: '/bookmarks/4',
	},
	{
		name: 'Paul Gramham',
		linkTo: 'http://paulgraham.com/',
		slug: '/bookmarks/5',
	},
	{
		name: 'xkcd',
		linkTo: 'https://xkcd.com',
		slug: '/bookmarks/6',
	},
	{
		name: 'Hyperbole and a half',
		linkTo: 'http://hyperboleandahalf.blogspot.com',
		slug: '/bookmarks/7',
	},
	{
		name: 'Noahpinion',
		linkTo: 'https://noahpinion.substack.com/',
		slug: '/bookmarks/8',
	},
	{
		name: 'Not Boring',
		linkTo: 'https://www.notboring.co',
		slug: '/bookmarks/9',
	},
	{
		name: 'Fanam Street',
		linkTo: 'https://fs.blog',
		slug: '/bookmarks/10',
	},
	{
		name: 'David Perell',
		linkTo: 'https://perell.com',
		slug: '/bookmarks/11',
	},
	{
		name: 'Chris Dixon',
		linkTo: 'https://cdixon.org/blog',
		slug: '/bookmarks/12',
	},
	{
		name: 'Sam Altman',
		linkTo: 'https://blog.samaltman.com',
		slug: '/bookmarks/13',
	},
	{
		name: 'Julie Zhuo',
		linkTo: 'https://www.juliezhuo.com',
		slug: '/bookmarks/14',
	},
	{
		name: 'If I Knew Then',
		linkTo: 'https://hbs1963.com',
		slug: '/bookmarks/15',
	},
	{
		name: 'zen habits',
		linkTo: 'https://zenhabits.net/archives/',
		slug: '/bookmarks/16',
	},
	{
		name: 'Joi Ito',
		linkTo: 'https://joi.ito.com',
		slug: '/bookmarks/17',
	},
	{
		name: 'a16z',
		linkTo: 'https://a16z.com',
		slug: '/bookmarks/18',
	},
	{
		name: 'Naked Capitalism',
		linkTo: 'https://www.nakedcapitalism.com',
		slug: '/bookmarks/19',
	},
	{
		name: 'Refactoring.Guru',
		linkTo: 'https://refactoring.guru/',
		slug: '/bookmarks/20',
	},
];

export default bookmarkListData;
